<template>
  <div class="mt-1">
    <code
        style="margin-right: 5px; text-transform: uppercase;"
        v-for="(variable, key) in variables"
        :title="variable"
        :ref="key"
        @click="copyVariable(key)"
    >
      {{ key }}
    </code>
  </div>
</template>

<script>
export default {
  props: {
    variables: {
      type: Object,
      required: true,
    },
  },
  methods: {
    copyVariable(value){
      navigator.clipboard.writeText("{"+value+"}");
      this.$helpers.showSuccessToast('Vágólapra másolva: '+"{"+value+"}")
    }
  }
}
</script>

<style>
  code{
    cursor: pointer;
  }
  code:hover{
    color: #7367f0;
  }
</style>
